<template>
  <v-alert
    text
    color="primary"
  >
    <h3 class="text-h5">
      {{ $t('turismo.menuContent.title', locale) }}
    </h3>
    <div>
      {{ $t('turismo.menuContent.description', locale) }}
    </div>

    <v-divider
      class="my-4 info"
      style="opacity: 0.22"
    ></v-divider>

    <v-row
      align="center"
      no-gutters
      v-if="isCreatePermission(permissions)"
    >
      <v-col>
        <v-btn
          v-if="workspaceID === 'f0550a87-3358-4876-bf24-0ac33e9a93cf'"
          color="primary"
          outlined
          @click="handleAddMenu"
        >
        {{ $t('turismo.menuContent.addMenu', locale) }}
        </v-btn>
      </v-col>
    </v-row>
  </v-alert>
</template>
<script>
import turismo from '@/services/turismo'
export default {
  props: {
    locale: {
      type: String,
      default: null,
    },
    onAddMenu: {
      type: Function,
      required: true,
    },
    permissions: {
      type: Array,
      default: null,
    },
    workspaceID: {
      type: String,
      required: true,
    },
  },
  methods: {
    isCreatePermission (permissions) {
      return turismo.checkCreatePermission(permissions)
    },
    handleAddMenu () {
      this.onAddMenu('aa5dd237-9e2a-401c-8b7c-0866e9486428')
    },
  },
}
</script>

