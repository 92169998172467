
const utils = {
  checkCreatePermission(v) {
    return v.indexOf('347f0443-59cd-4b24-88a4-a36a806f6c9e') >= 0
  },
  checkRequirePermission(v) {
    return v.indexOf('27106126-c349-4cd1-b8e2-3db2548d74b5') >= 0
  },
  checkUpdatePermission(v, owner) {
    return v.indexOf(owner ? '7bf52751-6225-4d7b-8f77-b0bb94e7b45c' : '09172814-3d67-4b6d-98f3-7ec63082f80f') >= 0
  },
  checkHideAllPermission(v) {
      return v.indexOf('6abe4a92-f4bc-4534-9b1a-d442e232b79d') >= 0
  },

  checkHidePermission(v) {
    return v.indexOf('94812691-41fd-4205-b96c-a58164733f22') >= 0
  },

}

export default utils

